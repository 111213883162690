<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
  <div class="row">
      <div class="col-lg-4">
        <div class="card br-t-white radius-lr10">
          <img class="card-img-top img-fluid radius-lr10" src="/img/bmm.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-5">BANK METRO MADANI</li>
              <li class="pr-5">Nomor Rek VA. {{ userName }}</li>
            </ul>
            <h3>Rp. {{ $filters.formatRp(totalTagihan) }}</h3>
            <label class="text-danger"><small>Pembayaran hanya bisa dilakukan di teller Bank Metro Madani</small></label>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Rincian tagihan pembayaran mahasiswa
            </p>
          </div>
          <div class="card-body padding-t-0">
            <template v-for="(row, num) in rinciTagihan" :key="row.id">
              <small class="text-muted text-fl">{{ num+1}}. {{ row.uraian }} </small>
              <p class="text-right mb-10"><small>Rp.</small> {{ $filters.formatRp(row.jumlah) }}</p>
            </template>
            <hr>
            <small class="text-muted text-fl">Total Tagihan</small>
            <p class="text-right"><small>Rp.</small> {{ $filters.formatRp(totalTagihan) }} </p>
            <button class="btn btn-success box-shadow btn-rounded mt-3 float-right">Download</button>
            <router-link to="/keuangan/tagihan" class="btn btn-warning box-shadow btn-rounded mt-3 float-right" >Kembali</router-link>
          </div>
        </div>
      </div>
  </div>
  <div class="modal fade konfirmasi-modal" tabindex="-1" role="dialog" aria-labelledby="myDefaultModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              class="fa fa-times"></span></button>
          <h5 class="modal-title" id="myDefaultModalLabel">Konfirmasi</h5>
        </div>
        <div class="modal-body">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-12 col-xs-12"> <strong>Anda akan melakukan pembayaran tagihan ini menggukan saldo KIP</strong>
                <br>
                <p class="text-muted">Jumlah tagihan Rp. {{ $filters.formatRp(totalTagihan) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">No, Tidak</button>
          <button type="button" v-on:click="prosesPembayaran()" class="btn btn-success">Yes, Setuju</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
import Loading from 'vue-loading-overlay'
import { getTagihan } from '@/services/keu.service'
import { useStore } from 'vuex'
export default {
  name: 'SlipBMM',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      totalTagihan: 0,
      rinciTagihan: [],
      isRegister: false,
      isLoading: false,
      fullPage: true,
      errorData: {}
    }
  },
  mounted () {
    this.handleTagihan()
  },
  methods: {
    async handleTagihan () {
      this.isLoading = true
      getTagihan({ nipd: this.userName }).then(response => {
        const rinci = response.records
        const total = response.total
        this.totalTagihan = total
        this.rinciTagihan = rinci
        this.isLoading = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
